import React, { Component }from 'react';

class ClubLabel extends Component {

    render() {
        const {entry, size} = this.props;

        return (
            <div className={`clubLabel ${size === "big" ? "big" : ""}`}>
                <div className="oar">
                    <img src={entry.club_oar} alt="" className="ui mini image" />
                </div>  
                <div className="name">
                    {size === "big" ? <h4>{entry.club}</h4> : <h5>{entry.club}</h5>}
                </div>                
            </div>
        );
    }
}

export default ClubLabel;