import React, { Component }from 'react';
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import ProgressBar from 'react-progress-bar-plus';
import 'react-progress-bar-plus/lib/progress-bar.css';

class Regattas extends Component {
    constructor({match}) {
        super();

        const date = new Date();

        this.match = match;

        this.state = {
            regattas: [],
            loading: true,
            filter: null,
            filteredRegattas: [],
            currentYear: date.getFullYear(),
            selectedYear: date.getFullYear()
        }
    }

    componentDidMount() {
        this.fetchRegattas();
    }

    fetchRegattas() {
        fetch(`https://w3v8ums6k0.execute-api.us-east-1.amazonaws.com/prod/regattas?year=${this.state.selectedYear}`)
            .then(response => response.json())
            .then(data => this.setState({
                regattas: data.regattas, 
                filteredRegattas: data.regattas, 
                loading: false
            }))
            .catch(error => console.error(error));
    }

    filterRegattas(str) {
        const filter = str.toLowerCase();

        return this.state.regattas.filter(r => r.description.toLowerCase().includes(filter));
    }
    
    handleFilterChange(e) {
        if(e.length > 2) {
            this.setState({
                filter: e,
                filteredRegattas: this.filterRegattas(e)
            })
        } else {
            this.setState({
                filter: e,
                filteredRegattas: this.state.regattas
            })
        }    
    }   
    
    handleYearChange(year) {
        this.setState({
            selectedYear: year,
            loading: true
        }, () => {
            this.fetchRegattas();
        });                
    }

    render() {
        const yearRange = Array.from({length: (this.state.currentYear+1 - 2016)}, (v, k) => k + 2016);

        return (
            <div>
                <ProgressBar percent={this.state.loading ? 0 : 100} spinner="right" autoIncrement={true} /> 
                <div className={`ui basic segment`}>
                    <div id="regattasTop">
                        <div>
                            <h1 className="title">{`Listado de regatas (${this.state.filteredRegattas.length})`}</h1>
                        </div>
                        <div>
                            <div className="ui form">
                                <div className="inline field">
                                    <label>A&ntilde;o</label>
                                    <select 
                                        className="ui dropdown"
                                        value={this.state.selectedYear} 
                                        onChange={e => this.handleYearChange(e.target.value)}>
                                        {yearRange.map(year => {
                                            return <option key={year} value={year}>{year}</option>
                                        })}
                                    </select> 
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div className="ui search">
                        <div className="ui icon fluid input">
                            <input 
                                className="prompt" 
                                type="text" 
                                placeholder="Buscar regata..."
                                onChange={e => this.handleFilterChange(e.target.value)} />
                            <i className="search icon"></i>
                        </div>
                    </div>

                    <table className={`ui unstackable table segment`}>
                        <thead>
                            <tr>
                                <th>Regata</th>
                                <th>Fecha</th>
                                <th>Sede</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.filteredRegattas.map((regatta) => {
                                return (
                                <tr key={regatta.id}>
                                    <td>
                                        <Link to={`/regattas/${regatta.id}`}>{regatta.description}</Link>
                                    </td>
                                    <td><Moment format="d/M/Y">{regatta.start_date}</Moment></td>
                                    <td>{regatta.location}</td>
                                </tr>  
                                );
                            })}
                            {this.state.regattas.length === 0 ? (
                                <tr key="empty-row">
                                    <td colSpan={3}>No hay regatas publicadas.</td>
                                </tr>
                            ) : ""}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Regattas;