import React, { Component }from 'react';
import Icon from './Icon';

class TopNav extends Component {

    render() {
        return (
            <div id="topNav" className="ui dark-blue inverted borderless top icon menu">
                <Icon />
                <div>
                    <h4>
                        <strong>Resultados de Regatas</strong> - 
                        Asociacion Argentina de Remeros Aficionados
                    </h4>
                </div>
            </div>
        );
    }
}

export default TopNav;