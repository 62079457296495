import React, { Component }from 'react';

class PositionLabel extends Component {

    render() {        
        const {position, size} = this.props;
        const labelConfig = {};
        
        if(Number.isInteger(position)) {
            switch (position) {
                case 1:
                    labelConfig.color = 'yellow';
                    if(size === "big") {
                        labelConfig.text = "1er Puesto";
                    } else {
                        labelConfig.text = "1";
                    }                
                    break;
                case 2:
                    labelConfig.color = 'gray';
                    if(size === "big") {
                        labelConfig.text = "2do Puesto";
                    } else {
                        labelConfig.text = "2";
                    } 
                    break;
                case 3:
                    labelConfig.color = 'brown';
                    if(size === "big") {
                        labelConfig.text = "3er Puesto";
                    } else {
                        labelConfig.text = "3";
                    } 
                    break;
                case 91:
                    labelConfig.color = 'black';
                    if(size === "big") {
                        labelConfig.text = "No Participo";
                    } else {
                        labelConfig.text = "N/P";
                    } 
                    break;
            
                default:
                    labelConfig.text = position;
                    break;
            }
    
            return (
                <span className={`ui ${size === "big" ? "fluid " : ""}circular ${labelConfig.color} label`}>{labelConfig.text}</span>
            );
        } else {
            return "";
        }        
    }
}

export default PositionLabel;