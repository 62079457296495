import React, { Component }from 'react';
import ClubLabel from './ClubLabel';
import PositionLabel from './PositionLabel';
import StatusLabel from './StatusLabel';

class CurrentEvent extends Component {

    getRowerPosition(position, boat_crew_size) {
        if(position > boat_crew_size) {
            return "S";
        }

        return position;
    }

    render() {
        const {event} = this.props;

        if(!event) {
            return (
                <div>
                    <h3 style={{color: "#CCC", textAlign: "center"}}>No hay resultados para esta regata.</h3>
                </div>
            );
        }

        return (
            <div>
                <div className="ui horizontal divider">
                    Prueba en curso o por comenzar
                </div>
               <div className="ui segment">
                    <StatusLabel 
                        status={event.state} 
                        displayType="current" />                    
                    <div>                    
                        <h3>{`Prueba Nro. ${event.number} - ${event.description} - ${event.distance}`}</h3>
                        <div id="entriesContainer" className="ui equal width grid">
                            {event.entries ? event.entries.map(entry => {
                                return (
                                    <div key={entry.lane} className="column entry">
                                        <h4 className="laneNumber">{`Cancha ${entry.lane}`}</h4>
                                        <ClubLabel entry={entry} size="big" />
                                        <div className="crew">
                                            {entry.crew.map(rower => {
                                                return(
                                                    <div key={rower.name}>{`${this.getRowerPosition(rower.position, event.boat_crew_size)}: ${rower.name}`}</div>
                                                );
                                            })}
                                        </div>
                                        <div className="time">T: <span className="timeValue">{entry.time}</span></div>
                                        <div className="position">
                                            <PositionLabel position={entry.position} size="big" />
                                        </div>
                                    </div>
                                );
                            }) : ''}
                            
                        </div>
                    </div>
                </div>
            </div>  
        );
    }
}

export default CurrentEvent;