import React, { Component }from 'react';
import PositionLabel from './PositionLabel';
import ClubLabel from './ClubLabel';
import StatusLabel from './StatusLabel';

class EventList extends Component {
    renderResults(events) {
        return events.map( event => {
            return (
                <div key={event.scheduleId} className="ui segment">
                    <div className="ui grid">
                        <div className="ten wide column">
                            <h4>{`Prueba Nro. ${event.number} - ${event.description}`} - {event.distance}</h4>
                            <p className="eventStartTime-List">{event.start_time}</p>
                        </div>
                        <div className="six wide column">
                        <StatusLabel 
                            status={event.state} 
                            displayType="list" />
                        </div>
                    </div>                                
                    <table className="ui compact table">
                        <tbody>
                            {event.entries.map(entry => {
                                return (
                                    <tr key={`${event.scheduleId}_${entry.entryId}_${entry.lane}_${entry.position}`}>
                                        <td className="one wide">
                                            <PositionLabel position={entry.position} size="small" />
                                        </td>
                                        <td className="two wide">{`Cancha ${entry.lane}`}</td>
                                        <td>
                                            <ClubLabel entry={entry} />
                                            <p className="crewRow">{entry.crew.map(rower => rower.name).join(" / ")}</p>
                                        </td>
                                        <td className="two wide">T: <span className="timeValue">{`${entry.time ? entry.time : ''}`}</span></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        })
    }

    renderTouringResults(events) {
        return (
            <table className="ui compact table">
                <thead>
                    <tr>
                        <th>Nro. Bote</th>
                        <th>Nro. Prueba</th>
                        <th>Prueba</th>
                        <th>Club</th>
                        <th>Hora de Largada</th>
                        <th>Tiempo</th>
                        <th>Puesto</th>
                    </tr>
                </thead>
                <tbody>
                    {events.map( event => {
                        return (
                            event.entries.map( entry => {
                                return (
                                    <tr>
                                        <td>{entry.boat_number}</td>
                                        <td>{event.number}</td>
                                        <td>{event.description}</td>
                                        <td>{entry.club}</td>
                                        <td>{event.start_time}</td>
                                        <td>{entry.time}</td>
                                        <td>{entry.position}</td>
                                    </tr>
                                );
                            })
                        );
                    })}
                </tbody>
            </table>
        );
    }

    render() {
        const {events, title, regatta} = this.props;

        return (
            <div className="eventListContainer">
                
                {events.length > 0 && regatta.category !== "Travesia" ? <div className="ui horizontal divider">{title}</div> : ""}
                
                {regatta.category === "Travesia" ? this.renderTouringResults(events) : this.renderResults(events)}
            </div>
        );
    }
}

export default EventList;