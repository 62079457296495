import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Regattas from './Regattas';
import Regatta from './Regatta';
import TopNav from './ui-components/TopNav';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <TopNav />
          <Route exact path="/" component={Regattas} />
          <Route exact path="/regattas" component={Regattas} />
          <Route path="/regattas/:regattaId" component={Regatta} />
        </div>        
      </Router>
    );
  }
}

export default App;
