import React, { Component }from 'react';
import { Link } from "react-router-dom";
import EventList from './ui-components/EventList';
import CurrentEvent from './ui-components/CurrentEvent';
import ProgressBar from 'react-progress-bar-plus';
import 'react-progress-bar-plus/lib/progress-bar.css';


class Regatta extends Component {
    constructor({ match }) {
        super();

        this.state = {
            events: [],
            regatta: {},
            currentEvent: null,
            loading: true,
            autoRefresh: false,
            regattaId: null
        }
    }

    componentWillMount() {  
        const regattaId = this.props.match.params.regattaId;

        this.setState({regattaId});

        this.getRegattaDetails(regattaId);
        this.getCurrentEvent(regattaId);
        this.getRegattaEvents(regattaId);
        
        this.timer = null;
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
      }

    getRegattaDetails(regattaId) {
        //  fetch regatta information
        fetch(`https://w3v8ums6k0.execute-api.us-east-1.amazonaws.com/prod/regattas/${regattaId}`)
            .then(response => response.json())
            .then(data => this.setState({regatta: data.regatta[0]}))
            .catch(error => console.error(error));
    }

    getCurrentEvent(regattaId) {
        // fetch current event
        fetch(`https://w3v8ums6k0.execute-api.us-east-1.amazonaws.com/prod/current-event/${regattaId}`)
            .then(response => response.json())
            .then(data => this.setState({currentEvent: data.events[0]}))
            .catch(error => console.error(error)); 
    }
    
    getRegattaEvents(regattaId) {
        // fetch regatta events
        fetch(`https://w3v8ums6k0.execute-api.us-east-1.amazonaws.com/prod/events/${regattaId}`)
            .then(response => response.json())
            .then(data => this.setState({events: data.events, loading: false}))
            .catch(error => console.error(error));
    }

    handleAutoRefreshChange() {
        if(this.state.autoRefresh) {
            this.setState({autoRefresh: false});
            clearInterval(this.timer);
            this.timer = null;
        } else {
            this.setState({autoRefresh: true});
            this.timer = setInterval(()=> {
                this.getCurrentEvent(this.state.regattaId);
                this.getRegattaEvents(this.state.regattaId);
            }, 10000);
        }
    }

    render() {
        const {currentEvent, loading, regatta, events} = this.state;

        return (
            <div>
                <ProgressBar percent={loading ? 0 : 100} spinner="right" autoIncrement={true} />         
                <div className={`ui basic segment`}>
                    <div>
                        <Link to={`/regattas`}>Volver al listado</Link>
                        
                        <div className="ui checkbox refreshCheckBox">
                            <input 
                                type="checkbox" 
                                name="autoRefresh"
                                checked={this.state.autoRefresh}
                                onChange={this.handleAutoRefreshChange.bind(this)} />
                            <label>Auto-refresh</label>
                        </div>                        
                    </div>
                    <h1 className="title">{regatta.description}</h1>
                                        
                    {currentEvent !== null && currentEvent.state !== "Resultado Oficial" && regatta.category !== "Travesia" ? <CurrentEvent event={currentEvent} /> : ""}

                    {events.length > 1 ? <EventList title="Listado de pruebas" regatta={regatta} events={events} /> : <div>Cargando pruebas...</div>}
                </div>
            </div>
        );
    }
}

export default Regatta;