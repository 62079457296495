import React, { Component } from 'react';
import Logo from './logo.svg';

class Icon extends Component {

    render() {
        return (
            <div>
                <img src={Logo} alt=""/>
            </div>
        );
    }
}

export default Icon;