import React, { Component }from 'react';

class StatusLabel extends Component {

    componentWillMount() {

    }
    
    render() {
        const {status, displayType} = this.props;
        let color;

        switch (status.toLowerCase()) {
            case "resultado oficial":
                color = "green";
                break;
            
            case "en curso":
                color = "blue";
                break;
        
            default:
                color = "";
                break;
        }

        if (displayType === "list") {
            return (
                <span className={`ui ${color} basic label eventStateLabel-List`}>{status}</span>
            );
        } else if(displayType === "current") {
            return (
                <div className={`ui top attached ${color} label eventStateLabel`}>{status}</div>
            );
        }
    }
}

StatusLabel.defaultProps = {
    status: "",
    displayType: "list"
};

export default StatusLabel;